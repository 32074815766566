

















































import Vue from "vue";
import { DataTableHeader } from "vuetify";
import EditCompanyDialog from "@/components/EditCompanyDialog.vue";
import AddCompanyDialog from "@/components/AddCompanyDialog.vue";
import Company from "@/store/models/Company";
import Request from "@/modules/request";
import rules from "@/modules/rules";

export default Vue.extend({
  components: {
    EditCompanyDialog,
    AddCompanyDialog,
  },
  async beforeMount() {
    await this.$store.dispatch("getCompanies");
  },
  data() {
    return {
      editIndex: 0,
      editDialog: false,
      addDialog: false,
      headers: [
        {
          text: "Nom",
          value: "name",
        },
        {
          text: "Active",
          value: "isActive",
          width: 80,
        },
        {
          text: "",
          value: "tools",
          width: 50,
        },
      ] as DataTableHeader[],
    };
  },
  methods: {
    async toggleCompany(company: Company) {
      try {
        await Request.shared.put(`company/${company.id}`, {
          isActive: company.isActive,
        });
        await this.$store.dispatch("getCompanies");
      } catch (error) {
        console.error(error);
      }
    },
    postUpdateCycle() {
      this.$store.dispatch("getCompanies");
    },
    openEditDialog(item: Record<string, any>) {
      this.editIndex = item.id;
      this.editDialog = true;
    },
    closeEditDialog() {
      this.editIndex = 0;
      this.editDialog = false;
    },
  },
});
