




































import Request from "@/modules/request";
import rules from "@/modules/rules";
import Vue from "vue";

export default Vue.extend({
  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    showDialog: {
      get(): boolean {
        return this.show;
      },
      set(value: boolean) {
        if (!value) {
          this.name = "";
          (this.$refs.addCompanyForm as any).resetValidation();
          this.$emit("close");
        }
      },
    },
  },
  data() {
    return {
      name: "",
      addLoading: false,
      valid: false,
      rules: rules(this),
    };
  },
  methods: {
    async addCompany() {
      try {
        this.addLoading = true;
        await Request.shared.post(`company`, { name: this.name });
        this.addLoading = false;
        this.showDialog = false;
        this.$emit("add");
      } catch (error) {
        // TBD
      }
    },
  },
});
