













































































































import base64Images from "@/modules/base64-images";
import Request from "@/modules/request";
import { MediaType } from "@/store/interfaces/MediaType";
import Company from "@/store/models/Company";
import MailDomain from "@/store/models/MailDomain";
import Vue from "vue";
import rules from "@/modules/rules";

export default Vue.extend({
  props: {
    id: {
      type: Number,
      required: true,
    },
    show: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    thumbnailFile: {
      get(): File | null {
        return this.thumbnail as File;
      },
      set(value: File) {
        try {
          if (value) {
            if (this.validateThumbnail(value)) {
              this.thumbnail = value;
              this.thumbnailSrc = URL.createObjectURL(value);
              this.thumbnailChanged = true;
            } else {
              this.thumbnail = null;
              this.thumbnailSrc = base64Images.thumbnailPlaceholder;
            }
          } else {
            this.thumbnail = value;
            this.thumbnailSrc = base64Images.thumbnailPlaceholder;
            this.thumbnailChanged = true;
          }
        } catch (error) {
          this.$store.dispatch("showAlert", {
            message: error.message,
            color: "warning",
            timeout: 4000,
          });
        }
      },
    },
    showDialog: {
      get(): boolean {
        return this.show;
      },
      set(value: boolean) {
        if (!value) {
          this.company = null;
          this.companyObjectLoaded = false;
          this.companyObjectChanged = false;
          this.thumbnailSrc = base64Images.thumbnailPlaceholder;
          (this.$refs.editCompanyForm as any).resetValidation();
          this.$emit("close");
          setTimeout(() => {
            this.thumbnailFile = null;
            this.thumbnailChanged = false;
            this.updateLoading = false;
          }, 300);
        }
      },
    },
  },
  data() {
    return {
      company: null as null | Company,
      companyObjectLoaded: false,
      companyObjectChanged: false,
      thumbnailSrc: base64Images.thumbnailPlaceholder,
      thumbnailChanged: false,
      thumbnail: null as File | null,
      domains: [] as Array<MailDomain | string>,
      deleteDialog: false,
      deleteLoading: false,
      updateLoading: false,
      valid: false,
      rules: rules(this),
    };
  },
  watch: {
    async id() {
      // If ID, then we fetch the details
      if (this.id) {
        // Fetch video object
        this.getCompany();
      }
    },
    company: {
      deep: true,
      handler(newValue, oldValue) {
        if (oldValue && this.companyObjectLoaded) {
          this.companyObjectChanged = true;
        }
      },
    },
  },
  methods: {
    async updateCompany() {
      try {
        this.updateLoading = true;
        if (this.company) {
          if (this.thumbnailChanged) {
            // If the user has deleted the thumbnail, we send the appropriate request (an empty thumbnail route)
            if (!this.thumbnailFile) {
              const thumbnailDeleted = await Request.shared.put(
                `company/${this.company.id}/logo`
              );
            } else {
              // The user has set a new thumbnail, we need to create the media and assign it to the video
              const mediaBody = {
                type: MediaType.logo,
                alt: this.thumbnailFile.name,
              };
              // We create the formdata
              const formData = new FormData();
              formData.append("body", JSON.stringify(mediaBody));
              formData.append("media", this.thumbnailFile);
              const media = await Request.shared.post("media", formData);
              // Once we got the media, we can assign the thumbnail to the video
              const assignedMedia = await Request.shared.put(
                `company/${this.company.id}/logo/${media.data.id}`
              );
            }
          }
          // We need to delete every maildomains to recreate them
          for (const domain of this.company!.MailDomains!) {
            await Request.shared.delete(
              `company/${this.company.id}/domain/${domain.id}`
            );
          }
          for (const domain of this.domains) {
            if (typeof domain == "object") {
              const createdDomain = await Request.shared.post(
                `company/${this.company.id}/domain/`,
                { value: domain.value }
              );
            } else {
              const createdDomain = await Request.shared.post(
                `company/${this.company.id}/domain/`,
                {
                  value: domain,
                }
              );
            }
          }

          // We need to get rid of these includes to prevent wrecking havoc
          delete this.company.MailDomains;
          delete this.company.Logo;
          // Update Video
          const companyUpdated = await Request.shared.put(
            `company/${this.company.id}`,
            this.company
          );
        }
        this.updateLoading = false;
        this.$store.dispatch("showAlert", {
          message: "L'entreprise a été mise à jour",
          color: "success",
          timeout: 4000,
        });
        // Rebuild original object
        await this.getCompany();
        this.$emit("updated");
        this.companyObjectChanged = false;
        this.thumbnailChanged = false;
      } catch (error) {
        console.error(error);
      }
    },
    async getCompany() {
      try {
        this.companyObjectLoaded = false;
        const company = await Request.shared.get(`company/${this.id}`);
        this.company = company.data;
        this.domains = this.company!.MailDomains!;

        if (this.company!.Logo) {
          try {
            const imageRequest = await Request.shared.get(
              `content/${this.$store.state.admin.uuid}/media/${this.company?.Logo.filename}`,
              {
                responseType: "blob",
              }
            );
            this.thumbnailSrc = URL.createObjectURL(imageRequest.data);
          } catch (error) {
            console.log(error);
            // Show alert but don't close
          }
        }
        this.companyObjectLoaded = true;
      } catch (error) {
        console.error(error);
      }
    },
    async deleteCompany(id: number) {
      this.deleteLoading = true;
      await Request.shared.delete(`company/${id}`);
      await this.$store.dispatch("getCompanies");
      this.deleteLoading = false;
      this.showDialog = false;
    },
    selectImage() {
      (this.$refs.thumbnailSelector as any).$refs.input.click();
    },
    handleDrop(e: DragEvent) {
      if (e.dataTransfer!.files.length) {
        this.thumbnailFile = e.dataTransfer!.files[0];
      }
    },
    validateThumbnail(file: File) {
      if (file.type == "image/png" || file.type == "image/jpeg") {
        // We check the file size
        if (file.size < 300000) {
          return true;
        } else {
          throw {
            message:
              "Le fichier est trop volumineux, sa taille doit être inférieure à 300ko",
          };
        }
      } else {
        throw {
          message: "Veuillez utiliser un fichier .jpg ou .png",
        };
      }
    },
  },
});
